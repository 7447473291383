import {
	createContext,
	useContext,
	useCallback,
	useEffect,
	useState,
} from 'react';
import debounce from 'lodash/debounce';
import { registerRetellWebCall } from '../config/functions';
import { agentId, retellClient } from '../config/retell';
import { useAuth } from './useAuth';

const RetellContext = createContext();

export function RetellProvider({ children }) {
	const retellVal = useProvideRetell();
	return (
		<RetellContext.Provider value={retellVal}>
			{children}
		</RetellContext.Provider>
	);
}

export const useRetell = () => {
	return useContext(RetellContext);
};

export const CALL_STATUS = {
	INACTIVE: 'inactive',
	ACTIVE: 'active',
	LOADING: 'loading',
};

function useProvideRetell() {
	const { user } = useAuth();
	const [callStatus, setCallStatus] = useState(CALL_STATUS.INACTIVE);
	const [audioData, setAudioData] = useState(null);
	const [audioLevel, setAudioLevel] = useState(0);
	const [isAgentTalking, setIsAgentTalking] = useState(false);
	const [metadata, setMetadata] = useState(null);

	const calculateRMS = (audio) => {
		let sumOfSquares = 0;
		for (let i = 0; i < audio.length; i++) {
			sumOfSquares += audio[i] * audio[i];
		}
		const meanSquare = sumOfSquares / audio.length;
		return Math.sqrt(meanSquare);
	};

	const handleConversationStarted = () => {
		setCallStatus(CALL_STATUS.ACTIVE);
	};

	const handleConversationEnded = () => {
		setCallStatus(CALL_STATUS.INACTIVE);
	};

	const handleAudio = (audio) => {
		const rms = calculateRMS(audio);
		const normalizedRMS = rms / 255;
		setAudioLevel(normalizedRMS);
	};

	const handleAgentStartTalking = () => {
		setIsAgentTalking(true);
	};

	const handleAgentStopTalking = () => {
		setIsAgentTalking(false);
	};

	const handleError = (err) => {
		console.error('Retell error:', err);
		setCallStatus(CALL_STATUS.INACTIVE);
	};

	const registerCall = async (agentId) => {
		try {
			const result = await registerRetellWebCall({
				agentId,
				metadata,
			});

			return result.data;
		} catch (err) {
			console.error('Failed to register Retell web call:', err);
			throw err;
		}
	};

	const startCall = async () => {
		setCallStatus(CALL_STATUS.LOADING);
		try {
			const registerCallResponse = await registerCall(agentId);

			if (registerCallResponse.call_id) {
				await retellClient.startConversation({
					callId: registerCallResponse.call_id,
					sampleRate: registerCallResponse.sample_rate,
					enableUpdate: true,
				});
			} else {
				throw new Error('No call_id returned from server call registration');
			}
		} catch (err) {
			console.error('Failed to start call:', err);
			setCallStatus(CALL_STATUS.INACTIVE);
		}
	};

	const stopCall = async () => {
		setCallStatus(CALL_STATUS.LOADING);
		try {
			retellClient.stopConversation();
		} catch (err) {
			console.error('Failed to stop call:', err);
		}
	};

	const toggleCall = useCallback(
		debounce(async () => {
			if (callStatus === CALL_STATUS.ACTIVE) {
				await stopCall();
			} else {
				await startCall();
			}
		}, 100),
		[callStatus, metadata]
	); // 100ms debounce

	// Setup event listeners
	useEffect(() => {
		// When the whole agent and user conversation starts
		retellClient.on('conversationStarted', handleConversationStarted);

		// When the whole agent and user conversation ends
		retellClient.on('conversationEnded', handleConversationEnded);

		// Update message such as transcript, turntaking information
		// retellClient.on('update', (update) => {
		// 	// Print live transcript as needed
		// 	console.log('update', update);
		// });

		// Metadata passed from custom LLM server
		// retellClient.on('metadata', (metadata) => {
		// 	console.log('metadata', metadata);
		// });

		// Agent audio in real time, can be used for animation
		retellClient.on('audio', handleAudio);

		// Signals agent audio starts playback, does not work when ambient sound is used
		// Useful for animation
		retellClient.on('agentStartTalking', handleAgentStartTalking);

		// Signals all agent audio in buffer has been played back, does not work when ambient sound is used
		// Useful for animation
		retellClient.on('agentStopTalking', handleAgentStopTalking);

		retellClient.on('error', handleError);

		return () => {
			if (callStatus === CALL_STATUS.ACTIVE) {
				retellClient.stopConversation();
			}
			retellClient.removeAllListeners();
		};
	}, []);

	useEffect(() => {
		if (user) {
			setMetadata({
				user,
			});
		}
	}, [user]);

	return {
		callStatus,
		audioData,
		audioLevel,
		isAgentTalking,
		toggleCall,
	};
}
